import { useDayjs } from '#dayjs'
import stripHtmlTags from '@/utils/stripHtmlTags'

export interface MeetingTypeDetails extends MeetingTypeResponse {
  photo1: string | undefined
  photo2: string | undefined
  photo3: string | undefined
  instructorString: string | undefined
  instructorsString: string
  durationFormatted: string
  priceFormatted: string | null
  wwwSlug: string
  eventType: 'session'
  typeTag: '1:1 Session'
  dateLong: string
  dateShort: string
  duration: string
}

export function getDateLong(date: string | Date) {
  const dayjs = useDayjs()

  return dayjs(date).format('MMMM D, YYYY [at] h:mmA')
}

export function getDateShort(date: string | Date) {
  const dayjs = useDayjs()

  return dayjs(date).format('MMM D, YYYY')
}

export function getTime(date: string | Date) {
  const dayjs = useDayjs()

  return dayjs(date).format('h:mm A')
}

export function formatDuration(minutes: number): string {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  if (hours === 0) return `${minutes} minutes`
  if (remainingMinutes === 0) return `${hours} hour${hours > 1 ? 's' : ''}`
  return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes} minutes`
}

export function formatPrice(price: number | null, wwwPrice?: string): string | null {
  if (wwwPrice) return wwwPrice
  if (price === null) return null
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(price)
}

export function getMeetingTypeSlug(meetingType: MeetingTypeResponse) {
  return `${meetingType.slug}-${meetingType.id}`
}

export function getDateFromMeetingTypeAvailability(availability: MeetingTypeAvailability) {
  const dayjs = useDayjs()
  const today = new Date()

  let daysKeys = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  const splitIndex = today.getDay() - 1
  daysKeys = [...daysKeys.slice(splitIndex), ...daysKeys.slice(0, splitIndex)]

  const nextDate = dayjs()
  daysKeys.find((day, index) => {
    if (availability.rules[day].enabled) {
      nextDate.add(index, 'day')
      return true
    }
    return false
  })

  return nextDate.toDate()
}

export function getMeetingTypeDetails(meeting: MeetingTypeResponse): MeetingTypeDetails {
  const photo1 = meeting.www_photo1?.image_url || meeting.cover_photo?.image_url
  const photo2 = meeting.www_photo2?.image_url || photo1
  const photo3 = meeting.www_photo3?.image_url || photo1

  const instructorsString = (meeting.instructors ?? [])
    .map(instructor => instructor.name)
    .join(', ')

  return {
    ...meeting,
    photo1,
    photo2,
    photo3,
    instructorString: (meeting.instructors ?? [])[0]?.name,
    instructorsString,
    durationFormatted: formatDuration(meeting.duration_minutes),
    priceFormatted: formatPrice(meeting.price, meeting.www_price),
    wwwSlug: getMeetingTypeSlug(meeting),
    dateLong: getDateLong(meeting.next_slot),
    dateShort: getDateShort(meeting.next_slot),
    duration: formatDuration(meeting.duration_minutes),
    eventType: 'session',
    typeTag: '1:1 Session'
  }
}

export function getMeetingTypeLd(meeting: MeetingTypeDetails) {
  return {
    '@context': 'https://schema.org',
    '@type': 'Service',
    name: meeting.name,
    description: stripHtmlTags(meeting.description || ''),
    provider: {
      '@type': 'Organization',
      name: meeting.instructorsString
    },
    offers: meeting.price ? {
      '@type': 'Offer',
      price: meeting.price,
      priceCurrency: 'USD'
    } : undefined,
    image: [meeting.photo1, meeting.photo2, meeting.photo3].filter(Boolean)
  }
} 