import { useFetch } from '#app'
import { useDashboardCacheStore } from '@/stores/dashboardCache'
import Cookies from 'js-cookie';
import { getEventDetails } from '@/utils/eventDetails';

export function useApi() {
  const config = useRuntimeConfig()
  const baseUrl = config.public.BASE_API_URL
  const dashboardCacheStore = useDashboardCacheStore()

  const accessTokenProxy = useCookie('wellT');

  function getHeaders() {

    let accessTokenValue;
    if(process.client) {
      accessTokenValue = Cookies.get('wellT');
    } else {
      accessTokenValue = accessTokenProxy.value;
    }
    return {
      'Authorization': `Token ${accessTokenValue}`
    }
  }

  const fetchCategories = async () => await useFetch<EventCategoriesResponse[]>(`${baseUrl}/api/event-categories`)
  const fetchDashboard = async () => {
    if (dashboardCacheStore.isValidCache) {
      return { data: {value: dashboardCacheStore.data}, error: null }
    }

    const response = await useFetch<DashboardResponse>(`${baseUrl}/api/wellwellwell/events/dashboard`)
    
    if (response.data.value) {
      response.data.value = {
        categories: response.data.value.categories.map(getEventDetails),
        featured: response.data.value.featured.map(getEventDetails),
        soon: response.data.value.soon.map(getEventDetails),
        newly_added: response.data.value.newly_added.map(getEventDetails),
        trending: response.data.value.trending.map(getEventDetails),
        past: response.data.value.past.map(getEventDetails)
      } as DashboardEvents

      dashboardCacheStore.setDashboardData(response.data.value)
    }

    return response
  }
  const fetchEvent = async (eventId: number) => await useFetch<EventResponse>(`${baseUrl}/api/wellwellwell/events/${eventId}`, {
    headers: getHeaders()
  })
  const getEvent = async (eventId: number) => await $fetch<EventResponse>(`${baseUrl}/api/wellwellwell/events/${eventId}`, {
    headers: getHeaders()
  }).then(getEventDetails)
  const fetchSimilarEvents = async (eventId: number): Promise<EventDetails[]> => {
    return await $fetch<EventResponse[]>(`${baseUrl}/api/wellwellwell/events/${eventId}/similar_events`)
      .then((res) => res.map(getEventDetails));
  }
  const fetchInstructorEvents = async (instructorId: number): Promise<EventDetails[]> => {
    return await $fetch<EventResponse[]>(`${baseUrl}/api/wellwellwell/events/events_by_instructor`,  {
      method: 'POST',
      body: JSON.stringify({ instructor_id: instructorId })
    }).then((res) => res.map(getEventDetails))
  }
  const fetchEvents = async (query: any): Promise<EventDetails[]> => {
    const categories = query.categories || []
    delete query.categories
    const urlSearchParams = new URLSearchParams(query)
    categories.forEach((category: number) => urlSearchParams.append('categories', category.toString()))

    return await $fetch<EventResponse[]>(`${baseUrl}/api/wellwellwell/events?${urlSearchParams.toString()}`)
      .then((res) => {return {...res, results:res.results.map(getEventDetails)}});
  }

  const fetchWidgetEvents = async (): Promise<EventDetails[]> => {
    return await $fetch<EventResponse[]>(`${baseUrl}/api/wellwellwell/events/widget_events`)
      .then((res) => res.map(getEventDetails));
  }

  // New function to create a payment intent for an event
  const createPaymentIntent = async (token: string, eventId: number, email: string, firstName: string, lastName: string): Promise<PaymentIntentResponse> => {
    return await $fetch(`${baseUrl}/api/wellwellwell/events/${eventId}/payment_intent`, {
      method: 'POST',
      body: JSON.stringify({ token, email, first_name: firstName, last_name: lastName }),
      headers: getHeaders()
    });
  }

  // New function to register for an event
  const registerForEvent = async (email: string, firstName: string, lastName: string, token: string, marketingOptin: boolean, eventId: number, paymentIntentId: string, paymentMethodId: string): Promise<EventRegistrationResponse> => {
    return await $fetch(`${baseUrl}/api/wellwellwell/events/${eventId}/register`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        first_name: firstName,
        last_name: lastName,
        token, 
        marketing_optin: marketingOptin,
        payment_intent_id: paymentIntentId, 
        payment_method_id: paymentMethodId,
      }),
      headers: getHeaders()
    });
  }

  const disableEvent = async (eventId: number, wwwAdminReviewNotes: string): Promise<EventResponse> => {
    return await $fetch(`${baseUrl}/api/wellwellwell/events/${eventId}/disable`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        www_admin_review_notes: wwwAdminReviewNotes
      })
    });
  }

  const toggleFeaturedEvent = async (eventId: number): Promise<EventResponse> => {
    return await $fetch(`${baseUrl}/api/wellwellwell/events/${eventId}/toggle_featured`, {
      method: 'POST',
      headers: getHeaders(),
    });
  }

  // Meetings Types
  const fetchMeetingTypeCategories = async () => await useFetch<EventCategoriesResponse[]>(`${baseUrl}/api/meeting-types-categories`)

  const fetchMeetingsTypes = async (query: any): Promise<PaginatedResponse<MeetingTypeDetails>> => {
    const categories = query.categories || []
    delete query.categories
    const urlSearchParams = new URLSearchParams(query)
    categories.forEach((category: number) => urlSearchParams.append('categories', category.toString()))

    return await $fetch<PaginatedResponse<MeetingTypeResponse>>(`${baseUrl}/api/wellwellwell/meetings-types?${urlSearchParams.toString()}`)
      .then((res) => {return {...res, results:res.results.map(getMeetingTypeDetails)}});
  }

  const fetchMeetingType = async (meetingTypeId: number) => await useFetch<MeetingTypeResponse>(`${baseUrl}/api/wellwellwell/meetings-types/${meetingTypeId}`, {
    headers: getHeaders()
  })

  const getMeetingType = async (meetingTypeId: number) => await $fetch<MeetingTypeResponse>(`${baseUrl}/api/wellwellwell/meetings-types/${meetingTypeId}`, {
    headers: getHeaders()
  }).then(getMeetingTypeDetails)

  const fetchSimilarMeetingTypes = async (meetingTypeId: number): Promise<MeetingTypeDetails[]> => {
    return await $fetch<MeetingTypeResponse[]>(`${baseUrl}/api/wellwellwell/meetings-types/${meetingTypeId}/similar_meeting_types`)
      .then((res) => res.map(getMeetingTypeDetails));
  }

  const fetchFeaturedMeetingTypes = async (): Promise<MeetingTypeDetails[]> => {
    return await $fetch<MeetingTypeResponse[]>(`${baseUrl}/api/wellwellwell/meetings-types/featured`)
      .then((res) => res.map(getMeetingTypeDetails));
  }

  const fetchInstructorMeetingTypes = async (instructorId: number): Promise<MeetingTypeDetails[]> => {
    return await $fetch<MeetingTypeResponse[]>(`${baseUrl}/api/wellwellwell/meetings-types/events_by_instructor`,  {
      method: 'POST',
      body: JSON.stringify({ instructor_id: instructorId })
    }).then((res) => res.map(getMeetingTypeDetails))
  }

  const disableMeetingType = async (meetingTypeId: number, wwwAdminReviewNotes: string): Promise<MeetingTypeResponse> => {
    return await $fetch(`${baseUrl}/api/wellwellwell/meetings-types/${meetingTypeId}/disable`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        www_admin_review_notes: wwwAdminReviewNotes
      })
    });
  }

  const toggleFeaturedMeetingType = async (meetingTypeId: number): Promise<MeetingTypeResponse> => {
    return await $fetch(`${baseUrl}/api/wellwellwell/meetings-types/${meetingTypeId}/toggle_featured`, {
      method: 'POST',
      headers: getHeaders(),
    });
  }

  const getMeetingTypeTimeSlot = async (
    studioSlug: string,
    meetingTypeSlug: string,
    timezone: string,
    startDate: Date,
    endDate: Date
  ): Promise<Array<string>> => {
    const params = {
      tz: timezone,
      start: startDate?.toISOString(),
      end: endDate?.toISOString(),
    };
    return await $fetch(`${baseUrl}/api/studios/${studioSlug}/meeting-types/${meetingTypeSlug}/get_time_slots`, {
      params
    })
  }

  const bookPublicMeetingSlot = async (
    studioURL: string,
    meetingTypeSlug: string,
    slot: Date,
    timezone: string,
    bookingFullName: string,
    bookingEmail: string,
    userEmail: string,
    userFirstName: string,
    userLastName: string,
    form: any,
    token: string,
    paymentIntentId: string,
    paymentMethodId: string,
    marketingOptIn: boolean,
  ): Promise<StudioMeetingResponse> => {
    const payload = {
      slot: slot.toISOString(),
      timezone,
      book_full_name: bookingFullName,
      book_email: bookingEmail,
      email: userEmail,
      first_name: userFirstName,
      last_name: userLastName,
      form,
      token,
      payment_intent_id: paymentIntentId,
      payment_method_id: paymentMethodId,
      marketing_opt_in: marketingOptIn,
      is_www_session: true,
    };
    const result = await $fetch<StudioMeetingResponse>(
      `${baseUrl}/api/studios/${studioURL}/meeting-types/${meetingTypeSlug}/book_public_meeting`,
      {
        method: 'POST',
        body: payload,
        headers: getHeaders()
      }
    );
    return result;
  }
  
  const meetingPaymentIntent = async (
    studioURL: string,
    meetingTypeSlug: string,
    slot: Date,
    timezone: string,
    firstName: string,
    lastName: string,
    email: string,
    token: string
  ): Promise<StudioMeetingResponse> => {
    const payload = {
      slot: slot.toISOString(),
      timezone,
      first_name: firstName,
      last_name: lastName,
      email: email,
      token,
      is_www_session: true,
    };
    const result = await $fetch<StudioMeetingResponse>(
      `${baseUrl}/api/studios/${studioURL}/meeting-types/${meetingTypeSlug}/payment_intent`,
      {
        method: 'POST',
        body: payload,
        headers: getHeaders()
      }
    );
    return result;
  }
  
  const login = async (email: string, password: string): Promise<LoginResponse> => {
    return await $fetch(`${baseUrl}/auth/login/`, {
      method: 'POST',
      body: JSON.stringify({ email, password })
    });
  }

  const googleLogin = async (accessToken: string): Promise<LoginResponse> => {
    return await $fetch(`${baseUrl}/auth/google/`, {
      method: 'POST',
      body: JSON.stringify({ access_token: accessToken }),
    });
  }

  const facebookLogin = async (accessToken: string): Promise<LoginResponse> => {
    return await $fetch(`${baseUrl}/auth/facebook/`, {
      method: 'POST',
      body: JSON.stringify({ access_token: accessToken }),
    });
  }

  const fetchUserInfo = async () => await useFetch(`${baseUrl}/api/students/me`, { headers: getHeaders() })

  const getUserInfo = async (): Promise<UserResponse> => {
    return await $fetch(`${baseUrl}/api/students/me`, {
      headers: getHeaders()
    });
  }



  return {
    fetchCategories,
    fetchDashboard,
    fetchEvents,
    fetchEvent,
    getEvent,
    fetchSimilarEvents,
    fetchInstructorEvents,
    fetchWidgetEvents,
    fetchFeaturedMeetingTypes,
    createPaymentIntent,
    registerForEvent,
    disableEvent,
    toggleFeaturedEvent,
    fetchMeetingTypeCategories,
    fetchMeetingsTypes,
    fetchMeetingType,
    getMeetingType,
    fetchSimilarMeetingTypes,
    bookPublicMeetingSlot,
    meetingPaymentIntent,
    fetchInstructorMeetingTypes,
    disableMeetingType,
    toggleFeaturedMeetingType,
    getMeetingTypeTimeSlot,
    login,
    fetchUserInfo,
    getUserInfo,
    googleLogin,
    facebookLogin
  }
}
